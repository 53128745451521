<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <b-overlay :show="register_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Add new domain"
                        @ok="addDomain"
                    >
                        <b-form>
                            <b-form-group label="domain">
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MailIcon" />
                                    </b-input-group-prepend>
                                    <b-form-input
                                        v-model="newDomain"
                                        type="text"
                                        placeholder="Domain ex. yourdomain.com"
                                        v-bind:[domainState]="domainValidation"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-bind:[domainState]="domainValidation"> domain format error </b-form-invalid-feedback>

                                <v-date-picker v-model="newDomainExpirationTime" timezone="UTC" is24hr>
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <b-form-group label="expiration date">
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <feather-icon icon="CalendarIcon" />
                                                </b-input-group-prepend>
                                                <b-form-input :value="inputValue" v-on:click="togglePopover" @keypress.prevent />
                                            </b-input-group>
                                        </b-form-group>
                                    </template>
                                </v-date-picker>
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>
        <div class="col-12">
            <b-card header="Manager Domain">
                <div class="pr-3 pl-3 pb-3 pt-0">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button variant="primary" class="mr-3" v-b-modal.modal-create> Add Domain </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="domain pattern" v-model="queryDomainPattern" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="user id" v-model="queryUserId" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="domain id" v-model="queryDomainId" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>

                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="pagination"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->

                                <span v-if="props.column.field === 'name'">
                                    <router-link :to="'/record/' + props.row.name + '/' + props.row.id">{{ props.row.name }}</router-link>
                                </span>

                                <span v-else-if="props.column.field === 'expiration_time'">
                                    <div v-if="props.row.name == 'root'"></div>
                                    <b-badge v-else  class="ml-1 mr-1" :variant="dateClass(props.row.expiration_time)">
                                        {{ parseTime(props.row.expiration_time) }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'forbidden'">
                                    <b-badge v-if="!props.row['forbidden']" pill variant="success" class="mr-3">active</b-badge>
                                    <b-badge v-else pill class="mr-3">forbidden</b-badge>
                                </span>
                                <span v-else-if="props.column.field === 'action1' && props.row.Name != 'root'">
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group>
                                    <label>Domain:</label>
                                    <b-form-input id="name" type="text" v-model="focusName" disabled />
                                </b-form-group>

                                <b-form-group>
                                    <label>Expiration:</label>
                                    <v-date-picker v-model="updateDomainExpirationTime" timezone="UTC" is24hr>
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <b-form-group>
                                                <b-input-group>
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon icon="CalendarIcon" />
                                                    </b-input-group-prepend>
                                                    <b-form-input :value="inputValue" v-on:click="togglePopover" @keypress.prevent />
                                                </b-input-group>
                                            </b-form-group>
                                        </template>
                                    </v-date-picker>
                                </b-form-group>

                                <b-form-group>
                                    <label>Active:</label>
                                    <b-form-checkbox v-model="updateDomainStatus" name="check-button" class="custom-control-success" switch>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left p-0">
                                    <b-button variant="danger" @click="deleteDomain">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right p-0">
                                    <b-button variant="primary" class="mr-3" @click="updateDomain">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormCheckbox,
    BPopover,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormCheckbox,
        BPopover,
        VueGoodTable,
        VDatePicker,
    },
    computed: {
        domainState() {
            return this.newDomain.length != 0 ? "state" : null;
        },
        domainValidation() {
            let match = this.$tools.validator.validateHostedDomain(this.newDomain)
            if (match === false) {
                return false;
            }
            if (this.newDomain.includes(" ")) {
                return false;
            }
            return true;
        },
    },

    methods: {
        dateClass(unixTimeStamp) {
            let nowTimeStamp = moment().unix();
            let gap = unixTimeStamp - nowTimeStamp;
            if (gap < 3600 * 24 * 32) {
                return "danger";
            } else if (gap < 3600 * 24 * 180) {
                return "warning";
            } else {
                return "success";
            }
        },

        parseTime(timeStamp) {
            return moment.unix(timeStamp).format("YYYY-MM-DD");
        },
        clearQueryCondition() {
            this.queryDomainPattern = "";
            this.queryUserId = "";
            this.queryDomainId = "";
        },
        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.focusName = this.focus.name;
            this.updateDomainExpirationTime = new Date(this.focus.expiration_time * 1000);
            this.updateDomainStatus = !this.focus.forbidden;
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs['remote_q_table'].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let resp = await this.$api.domain.queryDomain(
                this.queryDomainPattern,
                parseInt(this.queryUserId),
                parseInt(this.queryDomainId),
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.domain_list;
            this.totalRows = resp.result.count;
        },

        async updateDomain() {
            let eTime = (this.updateDomainExpirationTime / 1000).toFixed(0);
            let resp = await this.$api.domain.updateDomain(
                this.focus.id,
                parseInt(eTime),
                !this.updateDomainStatus,
                this.$store.state.auth.my_web_token
            );

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("domain updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
        },

        async deleteDomain() {
            let value = await this.$bvModal.msgBoxConfirm(
                `Please confirm that you want to delete Domain [${this.focus.name}] ? All the records of this domain will be deleted.`,
                {
                    title: "Delete Domain & Records",
                    okVariant: "danger",
                    okTitle: "Delete",
                    cancelTitle: "No",
                    cancelVariant: "secondary",
                    hideHeaderClose: false,
                    centered: true,
                }
            );
            if (value) {
                let resp = await this.$api.domain.deleteDomain(this.focus.id, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("domain deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        async addDomain(bvModalEvt) {
            if (this.domainValidation == false) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("domain format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (this.newDomainExpirationTime == 0) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("expiration time error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.register_show_overlay = true;
            let eTime = (this.newDomainExpirationTime / 1000).toFixed(0);
            let resp = await this.$api.domain.addDomain(this.newDomain, parseInt(eTime), this.$store.state.auth.my_web_token);
            this.register_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("domain added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
        },
    },
    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Domain",
                    field: "name",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "User ID",
                    field: "user_id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Expiration",
                    field: "expiration_time",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Active",
                    field: "forbidden",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            pagination: {
                enabled: true,
                mode: "pages",
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                setCurrentPage: 1,
            },
            row_data: [],

            queryDomainPattern: "",
            queryUserId: "",
            queryDomainId: "",
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: true,
            modal_row_view_overlay: false,
            register_show_overlay: false,

            focus: {},
            newDomain: "",
            newDomainExpirationTime: 0,
            updateDomainExpirationTime: 0,
            updateDomainStatus: true,
            focusName: "",
        };
    },
};
</script>
